<template>
  <div>
    <div v-if="am_allowed('product.can_see_product_services')">
      <div class="columns">
        <div class="column is-flex is-flex-direction-column pb-0">
          <div class="level p-0 m-0">
            <div class="level-left">
              <grid-col-manage
                :columnDefs="columnDefs.filter(f => !['action', 'name'].includes(f.field) )"
                :hiden_columns="hiden_columns"
                @toggle_column="toggle_column"
              ></grid-col-manage>
              <div class="level-item">Lista e sherbimeve</div>
              <input class="input" style="margin: 5px; width: 300px" type="text" placeholder="Kerko..."
                v-model="global_search_filter" @input="onFilterTextBoxChanged">
            </div>
            <div class="level-right">
              <div class="level">

                <a href="" @click.prevent="show_krudh('service_type')" class="mr-2">Tipe Servisesh</a> |
                <a class="ml-2" v-if="am_allowed('product.can_create_product_services')" @click.prevent="create_service">
                  <i class="fa fa-plus"></i>
                </a>
              </div>
            </div>
          </div>
          <ag-grid-vue 
            style="width: 100%; min-height: ; height:80vh;"
             class="ag-theme-balham is-flex-grow-1" 
             :columnDefs="columnDefs"
            :rowData="rowData.value" 
            :rowSelection="rowSelection" 
            :context="context" 
            :localeText="localeText"
            :animateRows="true" 
            @grid-ready="onGridReady">
          </ag-grid-vue>
        </div>
        <div class="column is-3" v-if="form_edit || form_create">
          <DaForm v-if="form_create" @submitted="submitService" @close="form_create = null" :closes_form="true"
            :form="form_create">
          </DaForm>

          <DaForm v-if="form_edit" @submitted="submitService" @close="form_edit = null" :closes_form="true"
            :form="form_edit">
          </DaForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import DaForm from "@/components/daform/index.vue";
import ButtonsCell from "@/components/buttonscell/ButtonsCell.vue";
import createService from "./forms/create";
import editService from "./forms/edit";
import locale from "@/components/ag-grid/locale/al/al.js"
import ServiceServices from "@/services/Service";
import { defineAsyncComponent } from "vue"
import socket from '@/socket.js';
import { onUnmounted, reactive, inject } from 'vue';
import permissionMixin from '@/mixins/permissions-mixin.js'
// import krudh from '@/components/krudh/'
import gridColManage from "@/components/grid_col_manage.vue";

import { $vfm } from 'vue-final-modal'

export default {
  mixins: [permissionMixin],
  components: {
    AgGridVue,
    DaForm,
    gridColManage,
    // krudh,
  },
  setup() {
    let rowData = reactive([])
    const toast = inject('toast')
    socket.connect()
    socket.on('welcome', data => {
      console.log('welcome', data)
    })
    socket.on("propagate", data => {
      if (data.action == "create") {
        var a = rowData.value
        a.push(data.data)
        rowData.value = [...a]
        toast.success('Sherbimi u krijua nga ' + data.created_by.name)
      }
      if (data.action == "edit") {
        rowData.value = [...rowData.value.map(e => {
          if (e.id == data.data.id) {
            return data.data
          }
          return e
        })]
        toast.success('Sherbimi u modifikua nga ' + data.created_by.name)
      }
    })
    onUnmounted(() => {
      socket.off('welcome')
      socket.off('propagate')
      socket.disconnect()
    })
    return {
      rowData
    }
  },
  beforeMount() {
    this.context = { componentParent: this }
  },
  data() {
    return {
      global_search_filter: "",
      context: null,
      selectedService: {},
      units: [],
      form_create: null,
      form_edit: null,
      gridApi: null,
      defaultColDef: {
        flex: 1,
        minWidth: 100
      },
      rowSelection: "single",
      localeText: null,
      hiden_columns: [],
      service_types: [],
    }
  },
  methods: {
    show_krudh(entity) {
      console.log(entity)
      var params = {
        fields: ['icon:Ikona,*name:Emri', 'description:Pershkrimi'],
        entity: 'service_types',
        columns: ['icon:80px', 'name:130px'],
        title: 'Tipe Sherbimesh'
      }
      $vfm.show({
        component: defineAsyncComponent(
          () => import("@/components/krudh-modal.vue")
        ), params
      })
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.global_search_filter)
    },
    async open(mode, service) {
      if (mode == "1") {
        if (this.am_allowed('product.can_edit_product_services')) {
          this.form_edit = null
          this.form_create = null
          if (service) {
            setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
            this.form_edit = { loading: true }
            this.form_edit = await editService(service)
            this.selectedService = service
            setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
          }
        } else {
          this.$toast.error('Ju nuk keni te drejta per te modifikuar sherbime.')
        }
      }
      if (mode == '0') {
        if (this.am_allowed('product.can_see_service_details')) {
          // console.log('show service details')
        } else {
          this.$toast.error('Ju nuk keni te drejta te shihni detajet e sherbimeve.')
        }
      }
    },
    onGridReady(params) {
      this.gridApi = params.api
      this.gridApi.sizeColumnsToFit();
      this.gridColumnApi = params.columnApi
    },
    async create_service() {
      this.form_edit = null
      this.form_create = { loading: true }
      setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
      this.form_create = await createService()
      setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
    },
    async submitService(s) {
      var notify_text = ""
      if (this.form_create != null) {
        let service = await ServiceServices.createServices(s)
        var a = this.rowData.value
        a.push(service)
        this.rowData.value = [...a]
        socket.emit("propagate", { action: "create", data: service })
        notify_text = "Sherbimi u krijua me sukses"
        this.form_create = null
        this.$root.selected_one = {}
      }
      else {
        s.guid = this.selectedService.guid
        let service = await ServiceServices.editServices(s)
        this.rowData.value = [...this.rowData.value.map(a => {
          if (a.guid == service.guid) a = { ...service }
          return a
        })]
        socket.emit("propagate", { action: "edit", data: service })
        notify_text = "Sherbimi u modfikua me sukses"
        this.form_edit = null
        this.$root.selected_one = {}
      }
      setTimeout(() => {
        this.gridApi.sizeColumnsToFit();
      }, 100);
      this.$toast.success(notify_text)
    },
    toggle_column(column) {
      if (this.hiden_columns.includes(column)) {
        this.hiden_columns = [...this.hiden_columns.filter(item => item != column)]
      } else {
        this.hiden_columns.push(column)
      }
      setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
    },
  },
  computed: {
    columnDefs(){
      var self = this
      var columns = [
        { headerName: "Kodi", 
          field: "code", 
          filter: true, 
          floatingFilter: true, 
          sortable: true, 
          hide: this.hiden_columns.includes("code") 
        },
        { headerName: "Emri", pinned: 'left', field: "name", filter: true, floatingFilter: true, sortable: true, resizable: true, },
        { headerName: "Pershkrimi", field: "description", filter: true, floatingFilter: true, sortable: true, hide: this.hiden_columns.includes("description"), resizable: true, },
        { headerName: "Tipi", field: "type", filter: true, floatingFilter: true, sortable: true, hide: this.hiden_columns.includes("type"),
          cellRenderer: function (params) {
            let r = self.service_types.find(e => e.id == params.value)
            return r ? r.name : ""
          },
        },
        { headerName: "Cmimi", field: "price", filter: true, floatingFilter: true, sortable: true, hide: this.hiden_columns.includes("price") },
        {
          headerName: "Action",
          cellRenderer: ButtonsCell,
          cellRendererParams: this.context,
          field: 'action',
          pinned: 'right',
        }
      ]
      return columns
    }
  },

  async created() {
    if (!this.am_allowed('product.can_see_product_services')) {
      window.location.href = '/home'
    }
    this.localeText = locale.locale_al()
    this.service_types = await ServiceServices.getServiceTypes()
    this.rowData.value = await ServiceServices.getServices()
  },
  onBeforeDestroy() {
    socket.disconnect();
  },

}
</script>

<style lang="scss" scoped>

</style>
